import React from 'react';
import './PriceCard.css'; // Import the CSS file

const PriceCard = () => {
  return (


    <>
      <div className="price-card-promo">
              <h3 className="heading-prop">Spring Exclusive Deal!</h3>
              <p className="body-text-prop">
                For a short time, our <strong>Premium Package</strong> includes free linen <strong>ENDS SOON</strong>.
              </p>
              <p className="body-text-prop">
                As a bonus, enjoy <strong>discounted photography and profile setup</strong>,at <strong>Cost price</strong>, for all new clients.
              </p>
              <p className="body-text-prop">
                Now is the perfect time to unlock your property’s full potential with <strong>AirBooked</strong>.
              </p>
        </div>

    <div className="price-card-container">
      
      <div className="price-card">
        <div className="price-card-heading-box">
          <h2 className="price-card-heading">
  <span className="crossed-out">22%</span> 16% Management
</h2>

        </div>
        <ul className="price-card-list">
          <li>✔ Schedule a Consultation</li>
          <li>✔ Personal Case Manager</li>
          <li>✔ Free professional Photography & Listing Creation (Valued at $1200)</li>
          <li>✔ Multi-Platform Marketing</li>
          <li>✔ Smart Pricing Strategies</li>
          <li>✔ Maximized Occupancy Rates</li>
          <li>✔ Complete Listing & Booking Management</li>
          <li>✔ Guest Screening</li>
          <li>✔ Regular Property Checks</li>
          <li>✔ Secure Key Lockbox</li>
          <li>✔ Customized Home Manuals</li>
          <li>✔ Mail Handling</li>
          <li>✔ Maintenance Oversight</li>
          <li>✔ Responsive Guest Support</li>
          <li>✔ Premium Linen & Towels Supplied (Guest Charged)</li>
          <li>✔ Household Essentials Pack</li>
          <li>✔ Complimentary Toiletries Pack</li>
          <li>✔ Emergency Call-Out Service</li>
        </ul>
      </div>
    </div>

    </>


  );
};

export default PriceCard;



